
@import './node_modules/bulma/bulma.sass';
@import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');

* {
  font-family: 'Prompt', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

html, body {
  background-color: rgba(0, 0, 0, .1);
}

.menu-bar {
  padding: 10px;
  width: 250px;
  min-height: 100vh;
  max-height: 100%;
  background-color: white;
  box-shadow: 0 0 10px 5px rgb(150, 150, 150);
  transition: 0.2s;
}

.menu li {
  margin-bottom: 5px;
}

.menu b {
  margin-left: 5px;
  font-weight: 400;
}

#menu {
  display: none;
  z-index: 10;
}

@media screen and (max-width: 1000px) {
  .menu-bar {
    width: 60px;
  }
  .menu b, .menu-label, .menu-bar h4 {
    display: none !important;
  }

  #menu {
    display: block;
  }
}